<template>
  <div>
    <table :class="`w-100 table--perweek table__border--${table_style}`">
      <thead :class="`table__head--${table_style}`">
        <th class="table__head--text">Naam</th>
        <th class="table__head--text">Aantal</th>
        <th class="table__head--text">Verkopen</th>
        <th class="table__head--text">Winst</th>
        <th class="table__head--text">% van de inkoop</th>
        <th class="table__head--text">% van de verkoop</th>
      </thead>
      <tbody>
        <tr
          v-for="(item, key) in table_data"
          :key="key"
          :class="`table__row--color`"
        >
          <td class="table__cell--default">{{ item.inkoper }}</td>
          <td class="table__cell--default table__data--right">{{ item.aantal }}</td>
          <td class="table__cell--default table__data--right">€ {{ item.verkopen }}</td>
          <td class="table__cell--default table__data--right">€ {{ item.winst }}</td>
          <td class="table__cell--default table__data--right">{{ item.margeinkoop }} %</td>
          <td class="table__cell--default table__data--right">{{ item.margeverkoop }} %</td>
        </tr>
      </tbody>
      <tfoot class="table__foot">
        <th class="table__foot--padding"></th>
        <th class="table__foot--padding table__data--right">{{ table_sum_data.aantal }}</th>
        <th class="table__foot--padding table__data--right">€ {{ table_sum_data.verkopen }}</th>
        <th class="table__foot--padding table__data--right">€ {{ table_sum_data.winst }}</th>
        <th class="table__foot--padding table__data--right">{{ table_sum_data.perc_inkoop }} %</th>
        <th class="table__foot--padding table__data--right">
          {{ table_sum_data.perc_verkoop }} %
        </th>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    table_data: [Object, Array],
    table_sum_data: [Object, Array],
    table_style: [String, Number],
  },
};
</script>