<template>
  <div>
    <table :class="`w-100 table--perweek table__border--${table_style}`">
      <thead :class="`table__head--${table_style}`">
        <th class="table__head--text">Naam</th>
        <th class="table__head--text table__data--right">Totaal</th>
        <th class="table__head--text table__data--right">Verlies</th>
        <th class="table__head--text table__data--right">Bruto verlies</th>
        <th class="table__head--text table__data--right">Verlies per stuk</th>
        <th class="table__head--text table__data--right">Verlies %</th>
        <th class="table__head--text table__data--right">Gem marge</th>
        <th class="table__head--text table__data--right">Marge</th>
        <th class="table__head--text table__data--right">% van inkoop</th>
        <th class="table__head--text table__data--right">% van verkoop</th>
      </thead>
      <tbody>
          <tr v-for="(item, key) in table_data" :key=key :class="`table__row--color`">
              <td class="table__cell--default">{{ item.naam }}</td>
              <td class="table__cell--default table__data--right">{{ item.totaalaantal }}</td>
              <td class="table__cell--default table__data--right">{{ item.aantalverlies }}</td>
              <td class="table__cell--default table__data--right">€ {{ item.brutoverlies }}</td>
              <td class="table__cell--default table__data--right">€ {{ item.verliesperstuk }}</td>
              <td class="table__cell--default table__data--right">{{ item.verliesperc }} %</td>
              <td class="table__cell--default table__data--right">€ {{ item.gemmarge }}</td>
              <td class="table__cell--default table__data--right">€ {{ item.brutowinst }}</td>
              <td class="table__cell--default table__data--right">{{ item.margeinkoop }} %</td>
              <td class="table__cell--default table__data--right">{{ item.margeverkoop }} %</td>
          </tr>
      </tbody>
      <tfoot class="table__foot">
          <th class="table__foot--padding">Totaal:</th>
          <th class="table__foot--padding table__data--right">{{ table_data_sum.aantal }}</th>
          <th class="table__foot--padding table__data--right">{{ table_data_sum.verlies }}</th>
          <th class="table__foot--padding table__data--right">€ {{ table_data_sum.bruto_verlies }}</th>
          <th class="table__foot--padding table__data--right">€ {{ table_data_sum.verlies_per_stuk }}</th>
          <th class="table__foot--padding table__data--right">{{ table_data_sum.verlies_perc }} %</th>
          <th class="table__foot--padding table__data--right">€ {{ table_data_sum.gem_marge }}</th>
          <th class="table__foot--padding table__data--right">€ {{ table_data_sum.marge }}</th>
          <th class="table__foot--padding table__data--right">{{ table_data_sum.perc_inkoop }} %</th>
          <th class="table__foot--padding table__data--right">{{ table_data_sum.perc_verkoop }} %</th>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
    props: {
        table_data: [Object, Array],
        table_data_sum: [Object, Array],
        table_style: [String, Number]
    }
};
</script>
